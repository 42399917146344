<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">New content in knowledge base<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="chapter.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">Publish <em
                      id="publishDate"
                      class="ms-2 text-muted"
                    ><small>12/12/2022</small></em></small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"> <!-- only admin -->
          <div class="mb-1 breadcrumb-right">
            <a
              href="#"
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to knowledge base"
              @click="$router.push({name: 'knowledge-base.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row d-flex align-items-end">
                    <div class="col-sm-10">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Section</label>
                        <v-select
                          v-model="chapter.section"
                          label="title"
                          :options="allSections"
                          :get-option-key="option => option.id"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <a
                          href="#"
                          class="btn btn-dark w-100"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasChapters"
                          aria-controls="offcanvasChapters"
                          @click="openAddSection()"
                        >Add & edit sections</a>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="row">
                    <div class="col-sm-9">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Title</label>
                        <input
                          v-model="chapter.title"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Visibility</label>
                        <select
                          id=""
                          v-model="chapter.onlyAdmin"
                          name=""
                          class="form-select"
                        >
                          <option value="true">
                            Admin only
                          </option>
                          <option value="false">
                            Public
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Content</label>
                        <quill-editor
                          v-model="chapter.content"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <hr>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >URL to course</label>
                        <input
                          v-model="chapter.url"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
          <button
            v-if="chapterId"
            id="type-danger"
            class="btn btn-danger mx-1"
            data-bs-toggle="modal"
            data-bs-target="#modalDeleteWithInput"
            @click="showDeleteModal(chapter.id, 'chapter')"
          >
            <i data-feather="trash-2" /> Delete
          </button>
        </div>

      </div>
    </div>
    <ItemInfoSheet>
      <template #info-sheet-title>
        Section
      </template>
      <template #info-sheet>
        <div class="offcanvas-body">
          <h6>Add a new section or edit</h6>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Title new section</label>
            <input
              v-model="section.title"
              type="text"
              class="form-control"
            >
          </div>
          <div class="mb-1">
            <button
              class="btn btn-sm btn-primary"
              @click="saveSection"
            >
              Add
            </button>
          </div>
          <hr>
          <ul
            id="basic-list-group"
            class="list-group"
          >
            <li
              v-for="(item, index) in allSections"
              :key="index"
              class="list-group-item draggable"
            >
              <div
                v-if="!isEditing(item)"
                class="d-flex"
              >
                <span class="icon-drag me-1"><i data-feather="menu" /></span>
                <div class="more-info">
                  {{ item.title }}
                  <span
                    v-if="item.active == true"
                    class="badge bg-light-success"
                  >active</span>
                  <span
                    v-if="item.active == false"
                    class="badge bg-light-danger"
                  >inactive</span>
                </div>
                <a
                  class="btn btn-icon rounded-circle btn-flat-secondary"
                  @click="selectedSectionsToEdit(index, item, 'edit')"
                ><i data-feather="edit-2" /></a>
                <a
                  class="btn btn-icon rounded-circle btn-flat-secondary"
                  @click="showDeleteModal(item.id, 'section')"
                ><i data-feather="trash-2" /></a>
              </div>
              <div
                v-else
                class="d-flex"
              >
                <span class="icon-drag"><i data-feather="menu" /></span>
                <div class="more-info">
                  <input
                    v-model="item.title"
                    type="text"
                    class="mx-1"
                  >
                  <input
                    v-model="item.active"
                    type="checkbox"
                    class="mx-1 form-checkbox-control"
                  >
                </div>
                <a
                  class="btn btn-icon rounded-circle btn-flat-secondary"
                  @click="selectedSectionsToEdit(index, item, 'cancel')"
                ><i data-feather="x" /></a>
              </div>
            </li>
          </ul>
          <div class="offcanvas-footer mt-auto">
            <button
              v-if="editingSection"
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="updateSection"
            >
              Apply changes
            </button>
          </div>
        </div>
      </template>
    </ItemInfoSheet>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '../../partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    ItemInfoSheet,
    vSelect,
  },
  data() {
    return {
      chapterId: this.$route.params.id,
      sending: false,
      errors: '',
      editingSection: false,
      selectedSections: [],
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      allSections: 'knowledgeBase/allSections',
      chapter: 'knowledgeBase/chapter',
      section: 'knowledgeBase/section',
    }),
  },
  async mounted() {
    if (this.chapterId) {
      await this.$store.dispatch('knowledgeBase/fetchChapter', this.chapterId)
    } else {
      await this.$store.dispatch('knowledgeBase/cleanChapter')
      this.chapter.onlyAdmin = false
    }

    this.$store.dispatch('knowledgeBase/fetchAllSections')
  },
  methods: {
    openAddSection() {
      this.editingSection = false
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async saveSection() {
      this.section.active = true
      await this.$store.dispatch('knowledgeBase/create', this.section)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.section.title = undefined
    },
    async save() {
      this.sending = true
      try {
        if (this.chapterId) {
          await this.$store.dispatch('knowledgeBase/updateChapter', { id: this.chapterId, data: this.chapter })
        } else {
          await this.$store.dispatch('knowledgeBase/createChapter', this.chapter)
        }
        this.errors = ''
      } catch (e) {
        console.log(e)
        this.errors = e.response
      }

      if (this.errors === '' && this.chapter.id) {
        Vue.swal('The chapter has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'knowledge-base.index' })
        })
      } else {
        console.log(this.errors)
        // Vue.swal
      }

      this.sending = false
    },
    async showDeleteModal(id, anotation) {
      if (anotation === 'chapter') {
        await this.$store.dispatch('modals/saveDeleteData', { name: 'knowledge-base.index', url: `${Vue.prototype.$groupUrl}/knowledge-base-chapters/${id}`, requiredFetch: false })
        this.$store.dispatch('modals/showDeleteModal', true)
      }

      if (anotation === 'section') {
        await this.$store.dispatch('modals/saveDeleteData', { name: ['knowledgeBase/fetchAllSections', 'knowledgeBase/fetch'], url: `${Vue.prototype.$groupUrl}/knowledge-base-sections/${id}` })
        await this.$store.dispatch('modals/showDeleteModal', true)
      }
    },
    selectedSectionsToEdit(key, value, method) {
      if (method === 'edit') {
        this.editingSection = true
        this.selectedSections.push(value)
      } else if (method === 'delete') {
        //
      } else if (method === 'cancel') {
        this.selectedSections.splice(this.selectedSections.indexOf(value), 1)
        this.editingSection = this.selectedSections.length > 0
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    isEditing(index) {
      return this.selectedSections.indexOf(index) > -1
    },
    async updateSection() {
      await this.$store.dispatch('knowledgeBase/update', {
        selectedSections: this.selectedSections,
      })
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
    },
  },
}
</script>

<style>

</style>
